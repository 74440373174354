import {SvgIconProps} from '@material-ui/core/SvgIcon'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'

const ArrowUpIcon = (props: SvgIconProps) => {
  const {style, ...otherProps} = props
  return (
    <KeyboardArrowUp
      fontSize="inherit"
      style={{fontSize: '14px', ...style}}
      {...otherProps}
    />
  )
}

export default ArrowUpIcon
